import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  FieldObject,
  PermissionArea,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { updateVariation } from '~/store/actions/variations'
import { useIsDrug, useIsVaccine, useIsVetDiet } from '~/store/hooks/orders'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getVariationIsSending } from '~/store/reducers/variations'
import {
  GlobalInventoryCatalogItem,
  InventoryItem,
  Price,
  Variation as VariationType,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useFieldsChanged from '~/utils/useFieldsChanged'

import VariationAdjustmentsSection from '../../variation/VariationAdjustmentsSection'
import VariationReOrderSection from '../../variation/VariationReOrderSection'
import VariationShipmentsSection from '../../variation/VariationShipmentsSection'
import VariationSection from '../VariationSection'
import { VariationBasicInformation } from './sections/VariationBasicInformation'
import { VariationPackaging } from './sections/VariationPackaging'
import { VariationPrice } from './sections/VariationPrice'
import { VariationRestriction } from './sections/VariationRestriction'
import { VariationRxInformation } from './sections/VariationRxInformation'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      overflowY: 'scroll',
    },
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    activeContainer: {
      height: 40,
      border: theme.constants.tabBorder,
      borderWidth: '1px 0',
    },
  }),
  { name: 'ReadOnlyVariation' },
)

const groupFieldsByPrice = (prices: Price[], fields: FieldObject) =>
  prices.map((price) => ({
    price,
    fields: {
      discountAllowed: fields[`${price.id}-discountAllowed`],
      taxable: fields[`${price.id}-taxable`],
    },
  }))

const DEFAULT_PLACEHOLDER_VALUE = '-'

interface ReadOnlyVariationHandle {
  getIsTouched: () => boolean
  save: () => void
}

interface ReadOnlyVariationProps {
  inventory: InventoryItem | GlobalInventoryCatalogItem
  onClose?: () => void
  variation: VariationType
}

export const ReadOnlyVariation = forwardRef<
  ReadOnlyVariationHandle,
  ReadOnlyVariationProps
>(({ inventory, variation, onClose }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])
  const [touched, setTouched] = useState(false)
  const dispatch = useDispatch()

  const shipmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.SHIPMENTS),
  )
  const adjustmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.ADJUSTMENTS),
  )

  const variationIsSending = useSelector(getVariationIsSending)
  const isLoading = variationIsSending

  const isDrug = useIsDrug(inventory)
  const isVaccine = useIsVaccine(inventory)
  const isVetDiet = useIsVetDiet(inventory)
  const isRx = isDrug || isVaccine || isVetDiet

  const closeAfterSave = useCloseAfterCreation(onClose, getVariationIsSending)

  const { prices = [] } = variation

  const { fields: variationFields } = useFields(
    [
      {
        name: 'reOrderPoint',
        label: t('Common:INVENTORY_RE-ORDER_POINT'),
        initialValue: variation.reorderPoint,
      },
      {
        name: 'reOrderQuantity',
        label: t('Common:INVENTORY_RE-ORDER_QUANTITY'),
        initialValue: variation.reorderQuantity,
      },
      {
        name: 'reOrderMaxQuantity',
        label: t('Common:MAXIMUM_QUANTITY'),
        initialValue: variation.maxQuantity,
      },
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue:
          typeof variation.active === 'undefined' || variation.active,
      },
    ],
    false,
  )

  const { fields: priceFields } = useFields(
    prices.flatMap(({ id, discountAllowed, taxable }) => [
      {
        name: `${id}-discountAllowed`,
        label: t('Common:ALLOW_DISCOUNT'),
        type: 'toggle',
        initialValue: discountAllowed,
      },
      {
        name: `${id}-taxable`,
        label: t('Common:TAXABLE'),
        type: 'toggle',
        initialValue: taxable,
      },
    ]),
  )

  useFieldsChanged(
    () => {
      setTouched(true)
    },
    { ...variationFields, ...priceFields },
  )

  const { active, reOrderPoint, reOrderQuantity, reOrderMaxQuantity } =
    variationFields

  const fieldsByPrice = groupFieldsByPrice(variation.prices, priceFields)

  const addOrUpdate = () => {
    const newVariation = {
      ...variation,
      prices: fieldsByPrice.map(
        ({ price, fields: { discountAllowed, taxable } }) => ({
          ...price,
          discountAllowed: discountAllowed.value,
          taxable: taxable.value,
        }),
      ),
      active: active.value,
      reorderPoint: reOrderPoint.value,
      reorderQuantity: reOrderQuantity.value,
      maxQuantity: reOrderMaxQuantity.value,
    }

    dispatch(updateVariation(inventory.id, newVariation))
    closeAfterSave()
  }

  useImperativeHandle(ref, () => ({
    save: addOrUpdate,
    getIsTouched: () => touched,
  }))

  const rxInfoTitle = isDrug
    ? t('Admin:CATALOG.VARIATION.DRUG_INFO')
    : isVetDiet
      ? t('Admin:CATALOG.VARIATION.VET_DIET_INFO')
      : isVaccine
        ? t('Admin:CATALOG.VARIATION.VACCINE_INFO')
        : ''

  const infoSections = [
    {
      id: 'basic-info',
      title: t('Admin:CATALOG.VARIATION.BASIC_INFO'),
      content: (
        <VariationBasicInformation
          defaultValue={DEFAULT_PLACEHOLDER_VALUE}
          variation={variation}
        />
      ),
    },

    {
      id: 'rx-info',
      title: rxInfoTitle,
      content: (
        <VariationRxInformation
          defaultValue={DEFAULT_PLACEHOLDER_VALUE}
          item={inventory}
          variation={variation}
        />
      ),
      hidden: !isRx,
    },
    {
      id: 'restriction-info',
      title: t('Common:RESTRICTION_ONE'),
      content: (
        <VariationRestriction
          defaultValue={DEFAULT_PLACEHOLDER_VALUE}
          variation={variation}
        />
      ),
      hidden: !isRx,
    },
  ]

  const accordionSections = [
    {
      id: 'packaging-info',
      title: t('Common:PACKAGING'),
      content: (
        <VariationPackaging
          defaultValue={DEFAULT_PLACEHOLDER_VALUE}
          variation={variation}
        />
      ),
      defaultExpanded: true,
    },
    {
      id: 'pricing-info',
      title: t('Common:PRICING'),
      content: <VariationPrice prices={fieldsByPrice} />,
    },
    {
      id: 'reorder-point-info',
      title: t('Common:INVENTORY_RE-ORDER_POINTS'),
      content: (
        <VariationReOrderSection
          fields={{
            point: reOrderPoint,
            quantity: reOrderQuantity,
            maxQuantity: reOrderMaxQuantity,
            pointUnitId: variation.reorderPointUnitId || '',
            quantityUnitId: variation.reorderQuantityUnitId || '',
            maxQuantityUnitId: variation.maxQuantityUnitId || '',
          }}
          packageTypeId={variation.packageTypeId}
          perPackageUnitsId={variation.perPackageUnitsId}
        />
      ),
    },
    {
      id: 'inventory-order-info',
      title: t('Common:ORDERS'),
      content: <VariationShipmentsSection variationId={variation.id} />,
      hidden: !shipmentPermissions.read,
    },
    {
      id: 'inventory-adjustment-info',
      title: t('Admin:CATALOG.VARIATION.ON-HAND_ADJUSTMENTS'),
      content: (
        <VariationAdjustmentsSection
          packageTypeId={variation.packageTypeId}
          perPackageAmount={variation.perPackageAmount}
          perPackageUnitsId={variation.perPackageUnitsId}
          variationId={variation.id}
          variationName={variation.name || ''}
          onHandAmount={variation.onHandAmount}
        />
      ),
      hidden: !adjustmentPermissions.read,
    },
  ]

  return (
    <Grid container direction="column" height={730} wrap="nowrap">
      <Grid container item direction="column" wrap="nowrap">
        <Grid item xs={12}>
          <Text pb={1} pt={2} px={3} variant="hero2">
            {t('Common:EDIT_VARIATION')}
          </Text>
        </Grid>
        <Grid item className={classes.activeContainer} px={3}>
          <PuiSwitch field={active} label={active.label} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        className={classes.content}
        direction="column"
        px={3}
        py={2}
        wrap="nowrap"
      >
        <Grid container item mb={3} spacing={3}>
          {infoSections.map(
            ({ id, title, content, hidden }) =>
              !hidden && (
                <Grid item key={id} width="100%">
                  <Text strong mb={1} variant="body">
                    {title}
                  </Text>
                  {content}
                </Grid>
              ),
          )}
        </Grid>
        <Grid item>
          {accordionSections.map(
            ({ id, title, defaultExpanded = false, content, hidden }) =>
              !hidden && (
                <VariationSection
                  defaultExpanded={defaultExpanded}
                  key={id}
                  title={title}
                >
                  {content}
                </VariationSection>
              ),
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.footer}
        justifyContent="end"
        px={3}
        py={2}
        wrap="nowrap"
      >
        <Grid item>
          <ButtonWithLoader
            disabled={!touched || isLoading}
            loading={isLoading}
            onClick={addOrUpdate}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </Grid>
  )
})
