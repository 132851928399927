import React from 'react'
import { useSelector } from 'react-redux'

import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getFeatureToggle } from '~/store/reducers/constants'

const UNSAVED_DATA = {
  CHEWY_ORDER: i18n.t(
    'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.CHEWY_ORDER',
  ),
  LAB_ORDER: i18n.t('Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.LAB_ORDER'),
  IMAGING_ORDER: i18n.t(
    'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.IMAGING_ORDER',
  ),
}

interface MissingFinalizationDialogProps {
  hasPendingChewyOrders: boolean
  hasPendingImagingOrders: boolean
  hasPendingLabOrders: boolean
  isAllowedRedirect: boolean
}

const MissingFinalizationDialog = ({
  hasPendingChewyOrders,
  hasPendingImagingOrders,
  hasPendingLabOrders,
  isAllowedRedirect,
}: MissingFinalizationDialogProps) => {
  const isContextualBackButtonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CONTEXTUAL_BACK_BUTTON),
  )

  return (
    <LeaveConfirmationDialog
      navigateOnCancel
      dialogName={DialogNames.SOAP_WRAP_UP_LEAVE}
      enableBeforeUnloadValue={false}
      getUnsavedData={() => {
        const unsavedData = []

        if (hasPendingLabOrders && !isAllowedRedirect) {
          unsavedData.push(UNSAVED_DATA.LAB_ORDER)
        }

        if (hasPendingImagingOrders && !isAllowedRedirect) {
          unsavedData.push(UNSAVED_DATA.IMAGING_ORDER)
        }

        if (hasPendingChewyOrders && !isAllowedRedirect) {
          unsavedData.push(UNSAVED_DATA.CHEWY_ORDER)
        }

        return unsavedData
      }}
      navigateOnProceed={false}
      replaceNavigation={{ onCancel: isContextualBackButtonEnabled }}
    />
  )
}

export default MissingFinalizationDialog
