import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Constant, LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import { useIsDrug, useIsVaccine, useIsVetDiet } from '~/store/hooks/orders'
import {
  getControlledSubstanceSchedules,
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getInventorySubCategory,
  getProductForm,
  getRabiesTagDefaultExpiration,
  getTimeUnits,
  getVaccineDefaultAmount,
  getVaccineDefaultDeliveryLocation,
  getVaccineDefaultDoseType,
  getVaccineDefaultDurationOfImmunity,
  getVaccineDeliveryMethod,
  getVaccineType,
} from '~/store/reducers/constants'
import { GlobalInventoryCatalogItem, InventoryItem, Variation } from '~/types'
import { getOneTimeUnitPluralized } from '~/utils/time'

interface VariationRxInformationProps {
  defaultValue?: string
  item: InventoryItem | GlobalInventoryCatalogItem
  variation: Variation
}

export const VariationRxInformation = ({
  item,
  variation,
  defaultValue,
}: VariationRxInformationProps) => {
  const { t } = useTranslation(['Admin', 'Common'])
  const isDrug = useIsDrug(item)
  const isVaccine = useIsVaccine(item)
  const isVetDiet = useIsVetDiet(item)

  const InventorySubCategory = useSelector(getInventorySubCategory)
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const ControlledSubstanceSchedules = useSelector(
    getControlledSubstanceSchedules,
  )
  const TimeUnits = useSelector(getTimeUnits)
  const VaccineDeliveryMethod = useSelector(getVaccineDeliveryMethod)
  const VaccineDefaultDeliveryLocation = useSelector(
    getVaccineDefaultDeliveryLocation,
  )
  const VaccineType = useSelector(getVaccineType)
  const VaccineDefaultDurationOfImmunity = useSelector(
    getVaccineDefaultDurationOfImmunity,
  )
  const VaccineDefaultDoseType = useSelector(getVaccineDefaultDoseType)
  const VaccineDefaultAmount = useSelector(getVaccineDefaultAmount)
  const RabiesTagDefaultExpiration = useSelector(getRabiesTagDefaultExpiration)
  const RabiesId = Utils.findConstantIdByName(
    'Rabies',
    InventorySubCategory[item.categoryId],
  )
  const isRabies = RabiesId === item.subcategoryId

  const isOtherConstantValue = (id: string, constant: Constant[]) =>
    Utils.getConstantName(id, constant) === 'Other'

  const getTimeUnitLabel = (value: number, unitId: string) =>
    `${value}\u00A0${getOneTimeUnitPluralized(
      Utils.getConstantName(unitId, TimeUnits, ''),
      value,
    )}`

  const drugInfoList = [
    {
      name: t('Common:FORM_NOUN'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.formId,
        ProductForm,
        defaultValue,
      ),
    },
    {
      name: t('Common:FLAVOR'),
      value: variation.flavor || defaultValue,
    },
    {
      name: t('Common:DRUG_DELIVERY_METHOD'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.deliveryMethodId,
        DrugDeliveryMethod,
        defaultValue,
      ),
    },
    {
      name: t('Common:DRUG_STRENGTH'),
      value: variation.strength || defaultValue,
    },
    {
      name: t('Common:DRUG_STRENGTH_COLOR'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.strengthColorId,
        DrugStrengthColor,
        defaultValue,
      ),
    },
    {
      name: t('Common:CONTROLLED_SUBSTANCE'),
      value: LanguageUtils.getConstantTranslatedName(
        item.controlledSubstanceScheduleId,
        ControlledSubstanceSchedules,
        defaultValue,
      ),
    },
  ]

  const vetDietInfoList = [
    {
      name: t('Common:FORM_NOUN'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.formId,
        ProductForm,
        defaultValue,
      ),
    },
    {
      name: t('Common:FLAVOR'),
      value: variation.flavor || defaultValue,
    },
  ]

  const vaccineInfoList = [
    {
      name: t('Common:FORM_NOUN'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.formId,
        ProductForm,
        defaultValue,
      ),
    },
    {
      name: t('Common:DRUG_DELIVERY_METHOD'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.vaccineDeliveryMethodId,
        VaccineDeliveryMethod,
        defaultValue,
      ),
    },
    {
      name: t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DELIVERY_LOCATION'),
      value: isOtherConstantValue(
        variation.vaccineDefaultDeliveryLocationId || '',
        VaccineDefaultDeliveryLocation,
      )
        ? variation.vaccineDefaultDeliveryLocation
        : LanguageUtils.getConstantTranslatedName(
            variation.vaccineDefaultDeliveryLocationId,
            VaccineDefaultDeliveryLocation,
            defaultValue,
          ),
    },
    {
      name: t('Common:VACCINE_TYPE'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.vaccineTypeId,
        VaccineType,
        defaultValue,
      ),
    },
    {
      name: t('Admin:CATALOG.VARIATION.DURATION_OF_IMMUNITY'),
      value: isOtherConstantValue(
        variation.vaccineDurationOfImmunityOptionId || '',
        VaccineDefaultDurationOfImmunity,
      )
        ? getTimeUnitLabel(
            Number(variation.vaccineDurationOfImmunity),
            variation.vaccineDurationOfImmunityUnitId || '',
          )
        : LanguageUtils.getConstantTranslatedName(
            variation.vaccineDurationOfImmunityOptionId,
            VaccineDefaultDurationOfImmunity,
            defaultValue,
          ),
    },
    {
      name: t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DOSE_TYPE'),
      value: LanguageUtils.getConstantTranslatedName(
        variation.vaccineDefaultDoseTypeId,
        VaccineDefaultDoseType,
        defaultValue,
      ),
    },
    {
      name: t('Common:DEFAULT_AMOUNT'),
      value: isOtherConstantValue(
        variation.vaccineDefaultAmountId || '',
        VaccineDefaultAmount,
      )
        ? variation.vaccineDefaultAmount
        : LanguageUtils.getConstantTranslatedName(
            variation.vaccineDefaultAmountId,
            VaccineDefaultAmount,
            defaultValue,
          ),
    },
    {
      name: t('Common:RABIES_TAG_EXPIRATION'),
      value: isOtherConstantValue(
        variation.rabiesTagExpirationOptionId,
        RabiesTagDefaultExpiration,
      )
        ? getTimeUnitLabel(
            variation.rabiesTagExpiration,
            variation.rabiesTagExpirationUnitId,
          )
        : LanguageUtils.getConstantTranslatedName(
            variation.rabiesTagExpirationOptionId,
            RabiesTagDefaultExpiration,
            defaultValue,
          ),
      hidden: !isRabies,
    },
  ]

  const getInfoList = () => {
    if (isDrug) {
      return drugInfoList
    }

    if (isVetDiet) {
      return vetDietInfoList
    }

    if (isVaccine) {
      return vaccineInfoList
    }

    return []
  }

  return <InfoList items={getInfoList()} />
}
