import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import {
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { Variation } from '~/types'

interface VariationPackagingProps {
  defaultValue?: string
  variation: Variation
}

export const VariationPackaging = ({
  variation,
  defaultValue,
}: VariationPackagingProps) => {
  const { t } = useTranslation('Common')
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  return (
    <InfoList
      items={[
        {
          name: t('Common:AMOUNT_PER_PACKAGE'),
          value: `${variation?.perPackageAmount} ${LanguageUtils.getConstantTranslatedName(variation?.perPackageUnitsId, InventoryProductSizeUnit)}`,
        },
        {
          name: t('Common:PACKAGE_TYPE'),
          value: LanguageUtils.getConstantTranslatedName(
            variation?.packageTypeId,
            PackageType,
            defaultValue,
          ),
        },
      ]}
    />
  )
}
