import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CurrencyTextField,
  NumberUtils,
  PermissionArea,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import Typography from '~/components/elements/Typography/Typography'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeChewyGiftCardFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'

const useStyles = makeStyles(
  () => ({
    numericInput: { width: 80 },
  }),
  { name: 'ChewyGiftCardConfigurationSection' },
)

const GIFT_CARD_MAX_AMOUNT = 50

const ChewyGiftCardConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.KYRIOS_CLINIC_ID),
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'enableChewyGiftCard',
        type: 'toggle',
        initialValue: Boolean(business.businessCvcSettings?.giftcardsEnabled),
      },
      {
        name: 'chewyGiftCardAmount',
        type: 'number',
        initialValue: business.businessCvcSettings?.giftcardAmount || 50,
      },
    ],
    false,
  )

  const { enableChewyGiftCard, chewyGiftCardAmount } = fields

  usePracticeFieldsSection<PracticeChewyGiftCardFields>({
    business,
    fields,
    parsedFields: {
      businessCvcSettings: {
        giftcardsEnabled: enableChewyGiftCard.value,
        giftcardAmount: chewyGiftCardAmount.value,
      },
    },
    sectionName: PracticeDetailsPanels.CHEWY_GIFT_CARD_CONFIGURATION,
    validate,
    reset,
  })

  return (
    <>
      <Typography.Paragraph>
        {t('Businesses:CHEWY_GIFT_CARD_CONFIGURATION.DESCRIPTION')}
      </Typography.Paragraph>
      <PuiSwitch
        disabled={!permissions.update}
        field={enableChewyGiftCard}
        label={
          <Typography.Paragraph>
            {t(
              'Businesses:CHEWY_GIFT_CARD_CONFIGURATION.ENABLE_CHEWY_GIFT_CARD',
            )}
          </Typography.Paragraph>
        }
      />
      {enableChewyGiftCard.value && (
        <Grid container item alignItems="center" spacing={1} wrap="nowrap">
          <Grid item>
            <Typography.Paragraph>
              {t('Common:GIFT_CARD_AMOUNT')}
            </Typography.Paragraph>
          </Grid>
          <Grid item>
            <CurrencyTextField
              className={classes.numericInput}
              disabled={!permissions.update}
              field={chewyGiftCardAmount}
              fullWidth={false}
              max={GIFT_CARD_MAX_AMOUNT}
              min={0}
            />
          </Grid>
          <Grid item>
            <Typography.Paragraph color="grayGray2">
              {t('Businesses:CHEWY_GIFT_CARD_CONFIGURATION.MAX_AMOUNT', {
                amount: NumberUtils.formatMoney(GIFT_CARD_MAX_AMOUNT),
              })}
            </Typography.Paragraph>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default memo(ChewyGiftCardConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
