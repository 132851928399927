import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { fetchBusiness } from '~/store/actions/businesses'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getBusinessIsGroup } from '~/utils/businessUtils'

export const useIsBusinessGroupCheck = (
  businessId: string | Nil,
  isFeatureEnabled: boolean,
) => {
  const dispatch = useDispatch()
  const business = useSelector(getBusiness(businessId))
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const isBusinessGroup = getBusinessIsGroup(business)
  const isCurrentBusinessChild = currentBusinessId !== businessId

  useEffect(() => {
    if (businessId && isCurrentBusinessChild && isFeatureEnabled) {
      dispatch(fetchBusiness(businessId))
    }
  }, [businessId, isCurrentBusinessChild, isFeatureEnabled])

  return isFeatureEnabled && isBusinessGroup && isCurrentBusinessChild
}
