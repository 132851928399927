import { gql } from '@apollo/client/core'

import {
  INVOICE_LINE_ITEM_FIELDS,
  INVOICE_LINE_ITEM_TOOLTIP_FIELDS,
  PATIENT_FIELDS,
  PAYMENT_METHODS_FIELDS,
  RETAIL_ORDER_LINE_ITEM_FIELDS,
} from '../fragments'
import { CHARGES_FIELDS } from '../fragments/charges'
import {
  CHARGE_SHEET_FIELDS,
  CHARGE_SHEET_GROUPPED_ITEMS,
  CLIENT_FIELDS,
  ORDER_FILTER,
  SHIPPING_ADDRESS_FIELDS,
} from '../fragments/chargeSheet'

export const getClientChargesQuery = (isIpoM1CheckoutEnabled: boolean) => gql`
  ${CLIENT_FIELDS(isIpoM1CheckoutEnabled)}
  ${CHARGE_SHEET_FIELDS(isIpoM1CheckoutEnabled)}
  ${PATIENT_FIELDS}
  ${isIpoM1CheckoutEnabled ? SHIPPING_ADDRESS_FIELDS : ''}
  ${isIpoM1CheckoutEnabled ? PAYMENT_METHODS_FIELDS : ''}
  query ClientCharges($id: UID!, $includeDeleted: Boolean) {
    client(id: $id, includeDeleted: $includeDeleted) {
      ...ClientFields
      chargeSheet {
        ...ChargeSheetFields
      }
      patients {
        ...PatientFields
      }
      ${isIpoM1CheckoutEnabled ? `...ShippingAddressFields` : ``}
      ${isIpoM1CheckoutEnabled ? `...PaymentMethodsFields` : ``}
    }
  }
`

// TODO refactor code to use chargesList
export const getClientSoapChargesQuery = (
  isIpoM1CheckoutEnabled: boolean,
) => gql`
  ${CHARGE_SHEET_FIELDS(isIpoM1CheckoutEnabled)}
  ${CHARGE_SHEET_GROUPPED_ITEMS(isIpoM1CheckoutEnabled)}
  ${PATIENT_FIELDS}
  ${CLIENT_FIELDS(isIpoM1CheckoutEnabled)}
  ${isIpoM1CheckoutEnabled ? SHIPPING_ADDRESS_FIELDS : ''}
  ${isIpoM1CheckoutEnabled ? PAYMENT_METHODS_FIELDS : ''}
  query ClientSoapCharges($id: UID!, $soapId: ID!, $includeDeleted: Boolean) {
    client(id: $id, includeDeleted: $includeDeleted) {
      ...ClientFields
      chargeSheet {
        ...ChargeSheetFields
      }
      patients {
        ...PatientFields
      }
      chargesList(soapId: $soapId) {
        items {
          ...ChargeSheetGroupedItems
        }
        totalAmount
      }
      ${isIpoM1CheckoutEnabled ? `...ShippingAddressFields` : ``}
      ${isIpoM1CheckoutEnabled ? `...PaymentMethodsFields` : ``}
    }
  }
`

export const FETCH_CLIENT_CHARGE_SHEET_ITEMS_COUNT = gql`
  query ClientChargeSheetItemsCount($id: UID!, $includeDeleted: Boolean) {
    client(id: $id, includeDeleted: $includeDeleted) {
      id
      chargeSheetItemsCount
    }
  }
`
export const FETCH_CLIENT_CHARGE_SHEET_BALANCE = gql`
  query ClientChargeSheetBalance($id: UID!, $includeDeleted: Boolean) {
    client(id: $id, includeDeleted: $includeDeleted) {
      id
      chargeSheet {
        id
        amount
        amountNoFee
        modificationDate
      }
    }
  }
`
export const FETCH_OPEN_CHARGE_SHEET = gql`
  query ChargeSheet($clientId: ID!) {
    chargeSheet(clientId: $clientId) {
      id
      subTotal
      totalDiscount
      totalTax
    }
  }
`
export const FETCH_ORDERS_FILTERS = gql`
  ${ORDER_FILTER}
  query OrderFilters($clientId: ID!, $patientId: ID!) {
    orderFilters(clientId: $clientId, patientId: $patientId) {
      ...OrderFilter
      children {
        ...OrderFilter
      }
    }
  }
`

export const FETCH_CHARGE_SHEET_LINE_ITEM = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  query InvoiceLineItemById(
    $businessId: ID
    $id: ID!
    $includeDeleted: Boolean
  ) {
    invoiceLineItem(
      businessId: $businessId
      id: $id
      includeDeleted: $includeDeleted
    ) {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
  }
`

export const FETCH_CHARGE_SHEET_RETAIL_ORDER_LINE_ITEM = gql`
  ${RETAIL_ORDER_LINE_ITEM_FIELDS}
  query RetailOrderLineItem($id: ID!) {
    retailOrderLineItem(id: $id) {
      ...RetailOrderLineItemFields
    }
  }
`

export const FETCH_LINE_ITEM_BY_LOG_ID = gql`
  query InvoiceLineItemByLogId($logId: ID!, $logType: BillableEntityType!) {
    invoiceLineItemByLog(logId: $logId, logType: $logType) {
      id
      name
    }
  }
`

export const FETCH_LINE_ITEM_BY_LOG_ID_EXTENDED = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  query InvoiceLineItemExtended($logId: ID!, $logType: BillableEntityType!) {
    invoiceLineItemByLog(logId: $logId, logType: $logType) {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
  }
`

export const FETCH_SOAP_CHARGES = gql`
  ${CHARGES_FIELDS}
  query SoapCharges($id: UID!, $soapId: ID!, $includeDeleted: Boolean) {
    client(id: $id, includeDeleted: $includeDeleted) {
      id
      charges(soapId: $soapId) {
        ...ChargesFields
      }
    }
  }
`
export const FECTH_CHARGES_BY_LOG = gql`
  ${CHARGES_FIELDS}
  query ChargesByLog($logId: ID!, $logType: BillableEntityType!) {
    chargesByLog(logId: $logId, logType: $logType) {
      ...ChargesFields
    }
  }
`

export const PENDINGS_FOR_OUTSTANDING_FINALIZATION_WHEN_POSTING_INVOICE = gql`
  query PendingsForOutstandingFinalizationWhenPostingInvoice(
    $clientId: ID!
    $sectionIds: [ID!]
  ) {
    pendingsForOutstandingFinalizationWhenPostingInvoice(
      clientId: $clientId
      sectionIds: $sectionIds
    ) {
      existsPendingLabTestsToBeFinalized
      existsPendingImagingRequestsToBeFinalized
      existsPendingActiveRxToBeFinalized
    }
  }
`

export const PENDING_LAB_TESTS_FOR_OUTSTANDING_FINALIZATION = gql`
  query PendingLabTestsForOutstandingFinalization(
    $clientId: ID!
    $invoiceId: ID
  ) {
    pendingLabTestsForOutstandingFinalization(
      clientId: $clientId
      invoiceId: $invoiceId
    ) {
      existsPendingLabTestsToBeFinalized
    }
  }
`

export const PENDING_IDEXX_IMAGING_FOR_OUTSTANDING_FINALIZATION = gql`
  query PendingIdexxImagingForOutstandingFinalization(
    $clientId: ID!
    $invoiceId: ID
  ) {
    pendingIdexxImagingForOutstandingFinalization(
      clientId: $clientId
      invoiceId: $invoiceId
    ) {
      existsPendingImagingRequestsToBeFinalized
    }
  }
`

export const PENDING_ACTIVE_RX_FOR_OUTSTANDING_FINALIZATION = gql`
  query PendingActiveRxForOutstandingFinalization($clientId: ID!) {
    pendingActiveRxForOutstandingFinalization(clientId: $clientId) {
      existsPendingActiveRxToBeFinalized
    }
  }
`
