import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Hidden, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, LanguageUtils, PuiSelect, Text } from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import { useInStockUnits } from '~/store/hooks/inventories'
import { getPackageType } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    divider: {
      alignSelf: 'center',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    reorderUnitLabel: {
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
    },
    priceTopLeftItem: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'VariationReOrderSection' },
)

export interface VariationReOrderSectionProps {
  fields: {
    maxQuantity: Field
    maxQuantityUnitId: Field | string
    point: Field
    pointUnitId: Field | string
    quantity: Field
    quantityUnitId: Field | string
  }
  packageTypeId: string
  perPackageUnitsId: string
}

const VariationReOrderSection = ({
  fields: {
    point,
    quantity,
    maxQuantity,
    pointUnitId,
    quantityUnitId,
    maxQuantityUnitId,
  },
  packageTypeId,
  perPackageUnitsId,
}: VariationReOrderSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const PackageType = useSelector(getPackageType)

  const inStockUnitOptions = useInStockUnits(perPackageUnitsId, packageTypeId)

  const renderReorderPointUnit = (reorderPointUnitId: Field | string) => (
    <FormControl fullWidth margin="none">
      {typeof reorderPointUnitId === 'string' ? (
        <Text className={classes.reorderUnitLabel}>
          {LanguageUtils.getConstantTranslatedName(
            reorderPointUnitId,
            PackageType,
          ) || t('Common:UNIT')}
        </Text>
      ) : (
        <>
          <InputLabel>
            {reorderPointUnitId.label || t('Common:UNIT')}
          </InputLabel>
          <PuiSelect
            renderEmpty
            field={reorderPointUnitId}
            items={inStockUnitOptions}
          />
        </>
      )}
    </FormControl>
  )

  return (
    <Grid container direction="column">
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={point}
            label={point.label || t('Common:INVENTORY_RE-ORDER_POINT')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          {renderReorderPointUnit(pointUnitId)}
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={quantity}
            label={quantity.label || t('Common:INVENTORY_RE-ORDER_QUANTITY')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          {renderReorderPointUnit(quantityUnitId)}
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.priceTopLeftItem} sm={2} xs={6}>
          <NumericInput
            allowDecimal
            field={maxQuantity}
            label={maxQuantity.label || t('Common:MAXIMUM_QUANTITY')}
            min={0}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <Grid container item alignItems="center" sm={3} xs={6}>
          {renderReorderPointUnit(maxQuantityUnitId)}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VariationReOrderSection
