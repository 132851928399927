import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType } from '@pbt/pbt-ui-components'

import DetailsBackButton from '../../dashboard/clients/DetailsBackButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    backButton: {
      width: 'max-content',
      minWidth: 'max-content',
    },
    backButtonContainer: {
      flex: 0,
      borderBottom: 'none',
    },
    backButtonIcon: {
      color: theme.colors.grayGray1,
    },
  }),
  { name: 'ContextualBackButton' },
)

interface ContextualBackButtonProps {
  buttonText: string
  classes?: ClassesType<typeof useStyles>
  navigateBackToContext: () => void
}

export const ContextualBackButton = ({
  buttonText,
  classes: classesProp,
  navigateBackToContext,
}: ContextualBackButtonProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles({ classes: classesProp })
  const navigate = useNavigate()

  const navigateBack = () => {
    if (window.history.state?.idx > 0) {
      navigate(-1)
    } else {
      navigateBackToContext()
    }
  }

  return (
    <Box
      alignItems="center"
      bgcolor={(theme) => theme.colors.grayGray4}
      className={classes.root}
      display="flex"
      gap={1}
      height={40}
      justifyContent="flex-start"
    >
      <DetailsBackButton
        thin
        classes={{
          container: classes.backButtonContainer,
          button: classes.backButton,
          thinArrow: classes.backButtonIcon,
        }}
        onClick={navigateBack}
      >
        {t('Common:BACK_ACTION')}
      </DetailsBackButton>
      <DetailsBackButton
        thin
        classes={{
          container: classes.backButtonContainer,
          button: classes.backButton,
        }}
        showIcon={false}
        onClick={navigateBackToContext}
      >
        {buttonText}
      </DetailsBackButton>
    </Box>
  )
}
