import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { AlertIconType, Defaults, IdObject, Nil } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { AttachmentGroup } from '~/types'
import useDialog from '~/utils/useDialog'

import MultiFileInput from '../../../file-input/MultiFileInput'
import AttachmentListWithFilters, {
  AttachmentListWithFiltersProps,
} from '../AttachmentListWithFilters'
import { FileListHandle } from './FileList'
import ImageEditor from './ImageEditor'
import MultiAttachmentDetails, {
  MultiAttachmentDetailsHandle,
} from './MultiAttachmentDetails'
import MultiAttachmentFiles from './MultiAttachmentFiles'

export interface MultiAttachmentProps {
  ListWithFiltersProps?: Partial<AttachmentListWithFiltersProps>
  accept?: string
  closeEdit: () => void
  date?: string
  eventId?: string | Nil
  fileIndexForImageEdit: number | null
  fileLimit?: number
  hideFileSelect?: boolean
  onDocumnentsUpload: (items: IdObject[]) => void
  onFilesUpload: (group: AttachmentGroup) => void
  patientId: string | Nil
  setFileIndexForImageEdit: (index: number) => void
  showDetails?: boolean
}

const MultiAttachmentFilters = ({
  patientId,
  eventId,
  ListWithFiltersProps,
  onDocumnentsUpload,
  onFilesUpload,
  closeEdit,
  fileIndexForImageEdit,
  setFileIndexForImageEdit,
  date,
  showDetails = false,
  hideFileSelect = false,
  fileLimit = 10,
  accept,
}: MultiAttachmentProps) => {
  const { t } = useTranslation('Common')

  const [files, setFiles] = useState<File[]>([])
  const [showFileInput, setShowFileInput] = useState(true)
  const isSaving = useSelector(getMedicalHistoryIsSending)
  const isPmrAiSummarizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PMR_AI_SUMMARIZATION),
  )

  const detailsRef = useRef<MultiAttachmentDetailsHandle>(null)
  const fileListRef = useRef<FileListHandle>(null)

  const [openFileAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const onListChange = (items: IdObject[]) => {
    setShowFileInput(!items || R.isEmpty(items))
  }

  const onAttachmentFilesSelected = (newFiles: File[]) => {
    const combinedFiles = [...files, ...newFiles]

    if (combinedFiles.length > fileLimit) {
      openFileAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            {fileLimit === 10
              ? t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE_COUNT')
              : t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE_COUNT_CUSTOM', {
                  fileLimit,
                })}
            &nbsp;
            <a href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </a>
            .
          </>
        ),
      })
      return
    }

    if (isPmrAiSummarizationEnabled && accept === '.pdf') {
      const nonPdfFiles = newFiles.filter(
        (file) => file.type !== 'application/pdf',
      )
      if (nonPdfFiles.length > 0) {
        openFileAlert({
          iconType: AlertIconType.WARN,
          message: t('Common:INPUTS.FILE_SELECTOR.ALERT_PDF_ONLY'),
        })
        return
      }
    }

    setFiles(combinedFiles)
  }

  const handleImageSave = (file: File) => {
    if (!R.isNil(fileIndexForImageEdit)) {
      setFiles(R.update(fileIndexForImageEdit, file, files))
      closeEdit()
    }
  }

  const handleDelete = (index: number) => {
    const newFilesList: File[] = R.remove(index, 1, files)
    setFiles(newFilesList)
  }

  const handleFileChange = (file: File, index: number) => {
    setFiles(R.update(index, file, files))
  }

  if (fileIndexForImageEdit !== null) {
    return (
      <ImageEditor
        file={files[fileIndexForImageEdit]}
        onCancel={closeEdit}
        onSave={handleImageSave}
      />
    )
  }

  if (files?.length) {
    if (showDetails) {
      return (
        <MultiAttachmentFiles
          accept={accept}
          files={files}
          handleDelete={handleDelete}
          handleFileChange={handleFileChange}
          isSaving={isPmrAiSummarizationEnabled && isSaving}
          ref={fileListRef}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          onAdd={() => {
            if (
              detailsRef.current?.validate() &&
              fileListRef.current?.validate()
            ) {
              onFilesUpload({ files, ...detailsRef.current.getDetails() })
            }
          }}
          onAttachmentFilesSelected={onAttachmentFilesSelected}
        >
          <MultiAttachmentDetails initialDate={date} ref={detailsRef} />
        </MultiAttachmentFiles>
      )
    }
    return (
      <MultiAttachmentFiles
        accept={accept}
        files={files}
        handleDelete={handleDelete}
        handleFileChange={handleFileChange}
        isSaving={isPmrAiSummarizationEnabled && isSaving}
        ref={fileListRef}
        setFileIndexForImageEdit={setFileIndexForImageEdit}
        onAdd={() => {
          if (fileListRef.current?.validate()) {
            onFilesUpload({ files })
          }
        }}
        onAttachmentFilesSelected={onAttachmentFilesSelected}
      />
    )
  }

  return (
    <>
      {showFileInput && (
        <MultiFileInput
          accept={accept}
          onFilesSelected={onAttachmentFilesSelected}
        />
      )}
      {!hideFileSelect && (
        <Grid container item direction="column" mt={2}>
          <AttachmentListWithFilters
            eventId={eventId}
            patientId={patientId}
            onChange={onListChange}
            onProceed={onDocumnentsUpload}
            {...ListWithFiltersProps}
          />
        </Grid>
      )}
    </>
  )
}

export default MultiAttachmentFilters
