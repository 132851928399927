import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'
import { Plus } from '@pbt/pbt-ui-components/src/icons'

import { Client } from '~/api/graphql/generated/types'
import { StatusError } from '~/components/elements/Status/Status'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import {
  getChargeSheet,
  getChargeSheetSubItemsMap,
  getClientFinanceClientId,
  getClientFinanceLoading,
  getIsCheckingPendingOutstandingOrdersWhilePostingInvoices,
  refetchChargeSheet,
} from '~/store/duck/clientFinanceData'
import {
  fetchAllPendingActiveRxForChargeSheet,
  getIsFetchingAllPendingActiveRxForChargeSheet,
} from '~/store/duck/prescriptions'
import { useIsAutoshipEnabled } from '~/store/hooks/business'
import {
  ChargeSheetSectionShort,
  useGetOpenAddInvoiceDialogAfterOrderFiltersFetched,
} from '~/store/hooks/chargeSheet'
import {
  useGetChargeSheetLogsByLogType,
  useGetHasOutstandingChewyOrders,
  useGetHasOutstandingImagingOrders,
  useGetHasOutstandingLabOrders,
  useHasUnfinishedLabs,
  useOutstandingImagingOrders,
} from '~/store/hooks/finance'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getChewyFreeShippingEnabled,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getFinanceIsFetching } from '~/store/reducers/finance'
import { getInvoiceV3Loading } from '~/store/reducers/invoiceV3'
import { getUser } from '~/store/reducers/users'
import { getIsFreeShippingEligible } from '~/utils/cvcClient'
import { doSelectedChargesHaveConflictingAutoships } from '~/utils/orderUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import { formatMoneyRange } from '../invoices/invoiceUtils'
import ChargeSheetAddItemPopper from './ChargeSheetAddItemPopper'
import FinanceDetailedFooterTable from './components/FinanceDetailedFooterTable'
import FinanceDropdownActions from './components/FinanceDropdownActions'
import MissingFinalizationDialog from './components/MissingFinalizationDialog'

const useStyles = makeStyles(
  (theme) => ({
    bottom: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
    },
    button: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0.5, 0),
    },
    total: {
      borderLeft: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    footNoteButton: {
      backgroundColor: theme.colors.tableBackground,
    },
    dotsButton: {
      width: 30,
      height: 30,
      color: theme.colors.filterBorderColor,
    },
    footnote: {
      fontSize: '1.2rem',
    },
    icon: {
      width: 12,
      height: 12,
      color: theme.colors.tabLabel,
    },
    footNote: {
      backgroundColor: theme.colors.tableBackground,
    },
  }),
  { name: 'ChargeSheetFooter' },
)

interface ChargeSheetFooterProps {
  clientId: string | Nil
  invoiceDisabled: boolean
  isChargeSheetContainsRetailOrder: boolean
  isRedirectToInvoice: boolean
  onPostInvoice: () => void
  selectedSections: string[]
  totals: {
    selectedDiscounts: number
    selectedRetailOrderDeliveryFee: number
    selectedRetailOrderShippingAmount: number
    selectedSectionAmount: number
    selectedSubTotal: number
    selectedTaxes: number
  }
}

const ChargeSheetFooter = ({
  clientId,
  invoiceDisabled,
  isRedirectToInvoice,
  onPostInvoice,
  selectedSections,
  totals,
  isChargeSheetContainsRetailOrder,
}: ChargeSheetFooterProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices'])
  const client = useSelector(getUser(clientId))

  const chargeSheet = useSelector(getChargeSheet)
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const business = useSelector(getCurrentBusiness)
  const chargeSheetItemsMap = useSelector(getChargeSheetSubItemsMap)
  const isLoading = useSelector(getClientFinanceLoading)
  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const isChewyFreeShippingEnabled = useSelector(getChewyFreeShippingEnabled)
  const isAutoshipEnabled = useIsAutoshipEnabled()
  const isCheckingPendingOutstandingOrdersWhilePostingInvoices = useSelector(
    getIsCheckingPendingOutstandingOrdersWhilePostingInvoices,
  )
  const isFetchingInvoice = useSelector(getFinanceIsFetching)
  const isInvoiceV3Loading = useSelector(getInvoiceV3Loading)

  const [openImagingOrdersManagementDialogV2] = useDialog(
    DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT_V2,
  )
  const doesChargeSheetHaveAutoshipConflicts = useMemo(
    () =>
      doSelectedChargesHaveConflictingAutoships(
        selectedSections,
        chargeSheetItemsMap,
        isAutoshipEnabled,
      ),
    [selectedSections, chargeSheetItemsMap, isAutoshipEnabled],
  )

  const { shippingAddresses = [], paymentMethods = [] } =
    (client as unknown as Client) || {}

  const shippingWarning =
    paymentMethods.length === 0
      ? t('Common:MISSING_PAYMENT_METHOD_TOOLTIP')
      : shippingAddresses.length === 0
        ? t('Common:MISSING_SHIPPING_ADDRESS_TOOLTIP', {
            name: client?.firstName,
          })
        : undefined

  const isFreeShippingEligible = getIsFreeShippingEligible(paymentMethods)

  const {
    selectedDiscounts,
    selectedSectionAmount,
    selectedSubTotal,
    selectedTaxes,
    selectedRetailOrderDeliveryFee,
    selectedRetailOrderShippingAmount,
  } = totals

  const labLogs = useGetChargeSheetLogsByLogType(
    OrderType.LAB_TEST,
    chargeSheet,
  )
  const hasUnfinishedLabs = useHasUnfinishedLabs(labLogs)
  const {
    hasLabs,
    hasPendingLabOrders,
    isLoading: isLoadingLabTests,
  } = useGetHasOutstandingLabOrders({ logs: labLogs, clientId })

  const procedureLogs = useGetChargeSheetLogsByLogType(
    OrderType.PROCEDURE,
    chargeSheet,
  )

  const prescriptionLogs = useGetChargeSheetLogsByLogType(
    OrderType.PRESCRIPTION,
    chargeSheet,
  )
  const hasOutstandingImagingOrders =
    useOutstandingImagingOrders(procedureLogs)?.length > 0
  const { hasPendingImagingOrders, isLoading: isLoadingImagingOrders } =
    useGetHasOutstandingImagingOrders({
      logs: procedureLogs,
      clientId,
    })

  const {
    hasOutstandingChewyOrders,
    hasPendingChewyActiveRxOrders,
    isLoading: isLoadingChewyRx,
  } = useGetHasOutstandingChewyOrders({
    logs: prescriptionLogs,
    clientId,
  })

  const onImagingManagementRequested = () => {
    if (chargeSheetClientId) {
      openImagingOrdersManagementDialogV2({
        hideOrderManagement: true,
        clientId: chargeSheetClientId,
      })
    }
  }
  const [openChargeSheetLabOrderManagementDialog] = useDialog(
    DialogNames.CHARGE_SHEET_LAB_ORDER_MANAGEMENT_DIALOG,
  )

  const [openFinalizeChewyActiveRxDialog] = useDialog(
    DialogNames.FINALIZE_CHEWY_ACTIVE_RX_DIALOG,
    () => {
      dispatch(refetchChargeSheet())
    },
  )

  const openAddDialogAfterFetch =
    useGetOpenAddInvoiceDialogAfterOrderFiltersFetched()

  const onLabOrderManagementRequested = () => {
    if (clientId) {
      openChargeSheetLabOrderManagementDialog({ clientId })
    }
  }

  const onClick = (chargeSheetSectionShort: ChargeSheetSectionShort) => {
    if (!R.isNil(chargeSheetItemsMap)) {
      const chargeSheetItem = chargeSheetItemsMap[chargeSheetSectionShort.id]
      const { groupedItems, soapId, eventId } = chargeSheetItem || {}
      const patientId =
        chargeSheetItem?.patientId || chargeSheetSectionShort.patientId
      if (chargeSheet?.id && clientId && patientId) {
        openAddDialogAfterFetch({
          id: chargeSheet.id,
          clientId,
          patientId,
          groupedItems,
          soapId,
          eventId,
        })
      }
    }
  }

  const actions = [
    {
      id: 'charge-sheet-group',
      isGroup: true,
      title: t('Common:ADD_TO_CHARGES'),
      items: [
        {
          id: 'internal-notes',
          Icon: Plus,
          label: t('Common:ITEM'),
          subItem: ChargeSheetAddItemPopper,
          onClick,
        },
      ],
    },
  ]

  const rowData = [
    {
      id: 'subtotal',
      name: t('Common:SUBTOTAL'),
      value: formatMoneyRange(selectedSubTotal),
    },
    {
      id: 'discount',
      name: t('Common:DISCOUNTS'),
      value: `(${formatMoneyRange(selectedDiscounts)})`,
    },
    {
      id: 'totalTax',
      name: t('Common:TAX'),
      value: formatMoneyRange(selectedTaxes),
    },
    ...(isChargeSheetContainsRetailOrder
      ? [
          {
            id: 'retailOrderShippingAmount',
            name:
              isChewyFreeShippingEnabled &&
              selectedRetailOrderShippingAmount === 0 &&
              isFreeShippingEligible
                ? t('Common:FREE_SHIPPING')
                : t('Common:SHIPPING'),
            // Currently, the backend value is always 0. This rule may need to be reviewed in the future.
            value: `${formatMoneyRange(selectedRetailOrderShippingAmount)}`,
            shippingWarning,
          },
          ...(selectedRetailOrderDeliveryFee > 0
            ? [
                {
                  id: 'retailOrderDeliveryFee',
                  name: t('Common:RETAIL_DELIVERY_FEE'),
                  value: `${formatMoneyRange(selectedRetailOrderDeliveryFee)}`,
                },
              ]
            : []),
        ]
      : []),
  ]

  const totalRowData = {
    name: (isExpanded: boolean) =>
      t('Common:TOTAL_WITH_VALUE', {
        amount: !isExpanded && formatMoneyRange(selectedSectionAmount),
      }),
    value: formatMoneyRange(selectedSectionAmount),
  }

  const openFinalizeChewyActiveRxDialogAfterFetch = useCloseAfterCreation(
    () => {
      openFinalizeChewyActiveRxDialog({
        businessId: chargeSheet?.businessId!,
        clientId: clientId!,
      })
    },
    getIsFetchingAllPendingActiveRxForChargeSheet,
  )

  const handleFinalizeChewyRx = () => {
    dispatch(
      fetchAllPendingActiveRxForChargeSheet({
        businessId: chargeSheet?.businessId!,
        clientId: clientId!,
      }),
    )
    openFinalizeChewyActiveRxDialogAfterFetch()
  }

  return (
    <Grid container className={classes.bottom} direction="row" mt="auto">
      <Grid
        container
        item
        alignItems="flex-end"
        justifyContent="space-between"
        lg={9.15}
        md={6.5}
        px={2}
        py={2}
      >
        <Grid container item display="inline" width="auto">
          {doesChargeSheetHaveAutoshipConflicts ? (
            <Grid container item mb={0.5} width="auto">
              <StatusError ellipsis={false}>
                {t('Common:DIFFERENT_AUTOSHIP_FREQ')}
              </StatusError>
            </Grid>
          ) : null}
          <Grid container item gap={1.5} width="auto">
            <ButtonWithLoader
              className={classes.button}
              disabled={invoiceDisabled || doesChargeSheetHaveAutoshipConflicts}
              loading={
                isCheckingPendingOutstandingOrdersWhilePostingInvoices ||
                isFetchingInvoice ||
                isInvoiceV3Loading
              }
              type="submit"
              onClick={onPostInvoice}
            >
              {t('Common:POST_INVOICE')}
            </ButtonWithLoader>
            {hasLabs && (
              <>
                {isChewyRxFinalizationEnabled ? (
                  <ButtonWithLoader
                    className={classes.button}
                    color={hasPendingLabOrders ? 'important' : 'secondary'}
                    disabled={isLoadingLabTests}
                    loading={isLoadingLabTests}
                    type="submit"
                    onClick={onLabOrderManagementRequested}
                  >
                    {hasPendingLabOrders || isLoadingLabTests
                      ? t('Common:FINALIZE_LABS')
                      : t('Common:VIEW_LAB_DETAILS')}
                  </ButtonWithLoader>
                ) : (
                  <ButtonWithLoader
                    className={classes.button}
                    color={hasUnfinishedLabs ? 'important' : 'secondary'}
                    disabled={isLoading}
                    loading={isLoading}
                    type="submit"
                    onClick={onLabOrderManagementRequested}
                  >
                    {hasUnfinishedLabs
                      ? t('Common:FINALIZE_LABS')
                      : t('Common:VIEW_LAB_DETAILS')}
                  </ButtonWithLoader>
                )}
              </>
            )}

            {isChewyRxFinalizationEnabled ? (
              <>
                {hasPendingImagingOrders && (
                  <ButtonWithLoader
                    className={classes.button}
                    color="important"
                    disabled={isLoadingImagingOrders}
                    loading={isLoadingImagingOrders}
                    type="submit"
                    onClick={onImagingManagementRequested}
                  >
                    {t('Common:FINALIZE_IMAGING')}
                  </ButtonWithLoader>
                )}
              </>
            ) : (
              <>
                {hasOutstandingImagingOrders && (
                  <ButtonWithLoader
                    className={classes.button}
                    color="important"
                    disabled={isLoading}
                    loading={isLoading}
                    type="submit"
                    onClick={onImagingManagementRequested}
                  >
                    {t('Common:FINALIZE_IMAGING')}
                  </ButtonWithLoader>
                )}
              </>
            )}

            {isChewyRxFinalizationEnabled &&
              hasOutstandingChewyOrders &&
              hasPendingChewyActiveRxOrders && (
                <ButtonWithLoader
                  className={classes.button}
                  color="important"
                  disabled={isLoadingChewyRx}
                  loading={isLoadingChewyRx}
                  type="submit"
                  onClick={handleFinalizeChewyRx}
                >
                  {t('Common:FINALIZE_CHEWY_RX')}
                </ButtonWithLoader>
              )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.footNote}
          gap={1.5}
          width="auto"
        >
          {business?.invoiceNotes && (
            <Text variant="body3">{business.invoiceNotes}</Text>
          )}
          <FinanceDropdownActions actions={actions} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.total}
        lg={2.85}
        md={5.5}
        px={2}
        py={2}
      >
        <FinanceDetailedFooterTable
          isGlobalFooter
          rowData={rowData}
          totalRowData={totalRowData}
        />
      </Grid>
      <MissingFinalizationDialog
        hasPendingChewyOrders={hasPendingChewyActiveRxOrders}
        hasPendingImagingOrders={
          isChewyRxFinalizationEnabled
            ? hasPendingImagingOrders
            : hasOutstandingImagingOrders
        }
        hasPendingLabOrders={
          isChewyRxFinalizationEnabled ? hasPendingLabOrders : hasUnfinishedLabs
        }
        isAllowedRedirect={isRedirectToInvoice}
      />
    </Grid>
  )
}

export default ChargeSheetFooter
