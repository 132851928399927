import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { formatNumberWithDecimal } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import {
  Payment,
  useUpdateCheckNumberForFailedPaymentTransactionMutation,
} from '~/api/graphql/generated/types'
import AlertLabel from '~/components/common/labels/AlertLabel'

import RefundPenaltyNotesSection from './RefundPenaltyNotesSection'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
      backgroundColor: `${theme.colors.uiError} !important`,
      padding: theme.spacing(0.25, 1, 0.25),
      gap: theme.spacing(0.25),
    },
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      width: 155,
    },
  }),
  { name: 'RefundPaymentDialog' },
)

interface RefundCheckNumberDialogProps extends BasePuiDialogProps {
  refundInvoiceId: string
  refundInvoiceOriginalInvoicePayment: Payment
}

const RefundCheckNumberDialog = ({
  open,
  onClose,
  refundInvoiceOriginalInvoicePayment,
  refundInvoiceId,
}: RefundCheckNumberDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const TitleWithAlert = (
    <Grid container direction="column" gap={1}>
      <Grid item>{t('Common:REFUND_NOUN')}</Grid>
      <Grid item>
        <AlertLabel
          showIcon
          clamp={2}
          className={classes.alert}
          message={
            <Trans
              components={{ br: <br /> }}
              i18nKey="Errors:NO_SHOW_PENALTY_FEE_REFUND_FAILED"
            />
          }
        />
      </Grid>
    </Grid>
  )

  const handleClose = () => {
    onClose?.()
  }

  const { fields: refundCheckNumberFields } = useFields(
    [
      {
        name: 'refundCheckNumberField',
        initialValue: '',
        validators: ['required'],
      },
    ],
    false,
  )

  const [updateCheckNumberForFailedPaymentTransaction] =
    useUpdateCheckNumberForFailedPaymentTransactionMutation()

  const { refundCheckNumberField } = refundCheckNumberFields

  const handleMarkAsRefundedClick = () => {
    updateCheckNumberForFailedPaymentTransaction({
      variables: {
        input: {
          refundInvoiceId,
          checkNumber: refundCheckNumberField.value,
        },
      },
      onCompleted: () => {
        // TODO: add a dispatch event to show the check number details in footer
        // TODO: https://chewyinc.atlassian.net/browse/CVC-15460
        handleClose()
      },
    })
  }

  return (
    <>
      <PuiDialog
        actions={
          <CustomWidthTooltip
            placement="top"
            title={
              refundCheckNumberField?.value === ''
                ? t('Tooltips:REFUND_CHECK_NUMBER_REQUIRED')
                : ''
            }
          >
            <span>
              <ButtonWithLoader
                className={classes.button}
                disabled={refundCheckNumberField?.value === ''}
                onClick={handleMarkAsRefundedClick}
              >
                {t('Common:MARK_AS_REFUNDED')}
              </ButtonWithLoader>
            </span>
          </CustomWidthTooltip>
        }
        aria-labelledby="refund-check-number-dialog"
        classes={{
          actions: classes.actions,
          paper: classes.paper,
        }}
        open={open}
        title={TitleWithAlert}
        onClose={handleClose}
      >
        <Grid container item>
          <Grid container item direction="column" p={3} xs={7}>
            <Grid item>
              <Text variant="body">
                {t('Common:REFUND_AMOUNT_WITH_VALUE', {
                  amount: formatNumberWithDecimal(
                    refundInvoiceOriginalInvoicePayment.amount,
                  ),
                })}
              </Text>
            </Grid>
            <Grid item>
              <PuiTextField
                required
                field={refundCheckNumberField}
                label={t('Common:REFUND_CHECK_NUMBER')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <RefundPenaltyNotesSection
              refundInvoiceOriginalInvoicePayment={
                refundInvoiceOriginalInvoicePayment
              }
            />
          </Grid>
        </Grid>
      </PuiDialog>
    </>
  )
}

export default RefundCheckNumberDialog
