import { useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  BusinessSettingsSections,
  getSectionFields,
} from '~/store/duck/businessSettings'

import { PracticeDetailsPanels } from './practices'

export function usePracticeDetailsFields(businessId: string) {
  const alertConfigurationFields = useSelector(
    getSectionFields<BusinessSettingsSections['ALERT_CONFIGURATION']['fields']>(
      businessId,
      PracticeDetailsPanels.ALERT_CONFIGURATION,
    ),
  )
  const appointmentCommunicationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['APPOINTMENT_COMMUNICATIONS']['fields']
    >(businessId, PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS),
  )
  const appointmentConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['APPOINTMENT_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.APPOINTMENT_CONFIGURATION),
  )
  const autoReplyCommunicationsFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['AUTO_REPLY_COMMUNICATIONS']['fields']
    >(businessId, PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS),
  )
  const boopCommunicationsConfigFields = useSelector(
    getSectionFields<BusinessSettingsSections['BOOP_COMMUNICATIONS']['fields']>(
      businessId,
      PracticeDetailsPanels.BOOP_COMMUNICATIONS,
    ),
  )
  const brandingConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['BRANDING_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.BRANDING_CONFIGURATION),
  )
  const chewyFields = useSelector(
    getSectionFields<BusinessSettingsSections['CHEWY']['fields']>(
      businessId,
      PracticeDetailsPanels.CHEWY,
    ),
  )
  const chewyGiftCardFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['CHEWY_GIFT_CARD_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.CHEWY_GIFT_CARD_CONFIGURATION),
  )
  const countrySectionFields = useSelector(
    getSectionFields<BusinessSettingsSections['COUNTRY_SECTION']['fields']>(
      businessId,
      PracticeDetailsPanels.COUNTRY_SECTION,
    ),
  )
  const discountConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['DISCOUNT_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.DISCOUNT_CONFIGURATION),
  )
  const discountReasonConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['DISCOUNT_REASON_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION),
  )
  const generalInformationFields = useSelector(
    getSectionFields<BusinessSettingsSections['GENERAL_INFORMATION']['fields']>(
      businessId,
      PracticeDetailsPanels.GENERAL_INFORMATION,
    ),
  )
  const groupDetailsConfigFields = useSelector(
    getSectionFields<BusinessSettingsSections['GROUP_DETAILS']['fields']>(
      businessId,
      PracticeDetailsPanels.GROUP_DETAILS,
    ),
  )
  const invoiceConfigurationsFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['INVOICE_ESTIMATE_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION),
  )
  const kioskConfigurationsFields = useSelector(
    getSectionFields<BusinessSettingsSections['KIOSK']['fields']>(
      businessId,
      PracticeDetailsPanels.KIOSK,
    ),
  )
  const labelConfigurationFields = useSelector(
    getSectionFields<BusinessSettingsSections['LABEL_CONFIGURATION']['fields']>(
      businessId,
      PracticeDetailsPanels.LABEL_CONFIGURATION,
    ),
  )
  const licensesFields = useSelector(
    getSectionFields<BusinessSettingsSections['LICENSES']['fields']>(
      businessId,
      PracticeDetailsPanels.LICENSES,
    ),
  )
  const localizationFields = useSelector(
    getSectionFields<BusinessSettingsSections['LOCALIZATIONS']['fields']>(
      businessId,
      PracticeDetailsPanels.LOCALIZATIONS,
    ),
  )
  const mainFields = useSelector(
    getSectionFields<BusinessSettingsSections['MAIN']['fields']>(
      businessId,
      PracticeDetailsPanels.MAIN,
    ),
  )
  const mobileFields = useSelector(
    getSectionFields<BusinessSettingsSections['MOBILE']['fields']>(
      businessId,
      PracticeDetailsPanels.MOBILE,
    ),
  )
  const npsSystemFields = useSelector(
    getSectionFields<BusinessSettingsSections['NPS_SYSTEM']['fields']>(
      businessId,
      PracticeDetailsPanels.NPS_SYSTEM,
    ),
  )
  const marketplaceManagementFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['MARKETPLACE_MANAGEMENT']['fields']
    >(businessId, PracticeDetailsPanels.MARKETPLACE_MANAGEMENT),
  )
  const savedSignatureConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['SAVED_SIGNATURE_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION),
  )
  const sharingFields = useSelector(
    getSectionFields<BusinessSettingsSections['SHARING']['fields']>(
      businessId,
      PracticeDetailsPanels.SHARING,
    ),
  )
  const soapConfigurationFields = useSelector(
    getSectionFields<BusinessSettingsSections['SOAP_CONFIGURATION']['fields']>(
      businessId,
      PracticeDetailsPanels.SOAP_CONFIGURATION,
    ),
  )
  const socialMediaFields = useSelector(
    getSectionFields<BusinessSettingsSections['SOCIAL_MEDIA']['fields']>(
      businessId,
      PracticeDetailsPanels.SOCIAL_MEDIA,
    ),
  )
  const recordSharingFields = useSelector(
    getSectionFields<BusinessSettingsSections['RECORD_SHARING']['fields']>(
      businessId,
      PracticeDetailsPanels.RECORD_SHARING,
    ),
  )
  const remindersSettingsFields = useSelector(
    getSectionFields<BusinessSettingsSections['REMINDER_SET_UP']['fields']>(
      businessId,
      PracticeDetailsPanels.REMINDER_SET_UP,
    ),
  )
  const taxConfigurationFields = useSelector(
    getSectionFields<BusinessSettingsSections['TAX_CONFIGURATION']['fields']>(
      businessId,
      PracticeDetailsPanels.TAX_CONFIGURATION,
    ),
  )
  const telehealthIntegrationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['TELEHEALTH_INTEGRATION']['fields']
    >(businessId, PracticeDetailsPanels.TELEHEALTH_INTEGRATION),
  )
  const vaccineCertificateFooterFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['VACCINE_CERTIFICATE_FOOTERS']['fields']
    >(businessId, PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS),
  )
  const unitsOfMeasurementFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['UNITS_OF_MEASUREMENT']['fields']
    >(businessId, PracticeDetailsPanels.UNITS_OF_MEASUREMENT),
  )
  const wellnessPlansFields = useSelector(
    getSectionFields<BusinessSettingsSections['WELLNESS_PLANS']['fields']>(
      businessId,
      PracticeDetailsPanels.WELLNESS_PLANS,
    ),
  )

  return {
    alertConfigurationFields,
    appointmentCommunicationFields,
    appointmentConfigurationFields: R.omit(
      ['occupyAllStaffTimeslots'],
      appointmentConfigurationFields,
    ),
    autoReplyCommunicationsFields,
    boopCommunicationsConfigFields,
    brandingConfigurationFields,
    chewyFields,
    chewyGiftCardFields,
    countrySectionFields,
    discountConfigurationFields,
    discountReasonConfigurationFields,
    generalInformationFields,
    groupDetailsConfigFields,
    invoiceConfigurationsFields,
    kioskConfigurationsFields,
    labelConfigurationFields,
    licensesFields,
    localizationFields,
    mainFields,
    mobileFields,
    npsSystemFields,
    marketplaceManagementFields,
    savedSignatureConfigurationFields,
    sharingFields,
    soapConfigurationFields,
    socialMediaFields,
    recordSharingFields,
    remindersSettingsFields,
    taxConfigurationFields,
    telehealthIntegrationFields,
    vaccineCertificateFooterFields,
    unitsOfMeasurementFields,
    wellnessPlansFields,
  }
}
