import * as R from 'ramda'
import { LanguageUtils, Nil, SentenceFormatter } from '@pbt/pbt-ui-components'
import { findById } from '@pbt/pbt-ui-components/src/utils'

import {
  Address,
  CardPaymentMethod,
  ChewyAccountBalancePaymentMethod,
  PaymentMethod,
  PayPalPaymentMethod,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import i18n from '~/locales/i18n'

export function getPrimary<T = any>(items: T[] = [], id?: string | Nil) {
  if (items.length === 0) {
    return null
  }

  const itemById = !R.isNil(id)
    ? items.find((item) => (item as any)?.id === id)
    : null
  const primaryItem = items.find((item) => (item as any)?.primary)

  return itemById ?? primaryItem ?? items[0]
}

export function formatShippingAddress(shippingAddress: ShippingAddress | Nil) {
  let formattedAddress = LanguageUtils.getSentence(
    [shippingAddress?.address?.street1, shippingAddress?.address?.city],
    SentenceFormatter.REGULAR,
    ', ',
  )
  if (formattedAddress === '') {
    formattedAddress = LanguageUtils.getSentence(
      [shippingAddress?.address?.state, shippingAddress?.address?.postcode],
      SentenceFormatter.REGULAR,
    )
  }
  return formattedAddress
}

export function formatCityStatePostcode(address: Address | Nil) {
  return LanguageUtils.getSentence(
    [address?.city && `${address.city},`, address?.state, address?.postcode],
    SentenceFormatter.REGULAR,
    ' ',
  )
}

export const formatCardPaymentMethod = (cardPaymentMethod: CardPaymentMethod) =>
  LanguageUtils.getSentence(
    [
      LanguageUtils.getSentence(
        [
          cardPaymentMethod.cardHolderName,
          cardPaymentMethod.paymentCardType?.name,
        ],
        SentenceFormatter.REGULAR,
        ' | ',
      ),
      cardPaymentMethod.lastFour,
    ],
    SentenceFormatter.REGULAR,
  )

export const formatPayPalPaymentMethod = (
  payPalPaymentMethod: PayPalPaymentMethod,
) =>
  LanguageUtils.getSentence(
    [i18n.t<string>('Payments:PAY_PAL'), payPalPaymentMethod.email],
    SentenceFormatter.REGULAR,
  )

export const formatPaymentMethod = (
  paymentMethod: CardPaymentMethod | PayPalPaymentMethod,
) => {
  switch (paymentMethod.__typename) {
    case 'CardPaymentMethod':
      return formatCardPaymentMethod(paymentMethod)
    case 'PayPalPaymentMethod':
      return formatPayPalPaymentMethod(paymentMethod)
    default:
      return ''
  }
}

export type CardAndPaypalPaymentMethods =
  | CardPaymentMethod
  | PayPalPaymentMethod

export const getCardAndPaypalPaymentMethods = (
  paymentMethods: PaymentMethod[],
) =>
  paymentMethods.filter(
    (paymentMethod): paymentMethod is CardAndPaypalPaymentMethods =>
      paymentMethod.__typename === 'CardPaymentMethod' ||
      paymentMethod.__typename === 'PayPalPaymentMethod',
  )

export const getPrimaryCardPaymentMethod = (paymentMethods: PaymentMethod[]) =>
  paymentMethods?.filter(
    (paymentMethod): paymentMethod is CardPaymentMethod =>
      paymentMethod.__typename === 'CardPaymentMethod',
  ) ?? []

export const getChewyAccountBalancePaymentMethod = (
  paymentMethods: PaymentMethod[],
) =>
  paymentMethods.find(
    (paymentMethod): paymentMethod is ChewyAccountBalancePaymentMethod =>
      paymentMethod.__typename === 'ChewyAccountBalancePaymentMethod',
  )

export const getPaymentMethodById = (
  id: string | Nil,
  paymentMethods: CardPaymentMethod[],
) => findById(id, paymentMethods) as CardPaymentMethod

export const sortCardPaymentMethodsByPrimary = (
  paymentMethods: CardPaymentMethod[],
) => {
  const comparator = (
    paymentMethodA: CardPaymentMethod,
    paymentMethodB: CardPaymentMethod,
  ) => {
    if (paymentMethodA.primary && !paymentMethodB.primary) return -1
    if (!paymentMethodA.primary && paymentMethodB.primary) return 1
    return 0
  }
  const sortedCardPaymentMethods = R.sort(comparator, paymentMethods)
  return sortedCardPaymentMethods
}

// Pet parent has a card on file in their Chewy.com wallet
export const getIsFreeShippingEligible = (paymentMethods: PaymentMethod[]) =>
  paymentMethods.some(
    (paymentMethod) => paymentMethod.__typename === 'CardPaymentMethod',
  )
