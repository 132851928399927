import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberUtils } from '@pbt/pbt-ui-components'

import { Payment } from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.colors.tableLeftColumnBackground,
    },
  }),
  { name: 'RefundPenaltyNotesSection' },
)

export interface RefundPenaltyNotesSectionProps {
  refundInvoiceOriginalInvoicePayment: Payment
}

const RefundPenaltyNotesSection = ({
  refundInvoiceOriginalInvoicePayment,
}: RefundPenaltyNotesSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container item className={classes.root} p={3}>
      <Typography.H3>
        {t('Invoices:TO_BE_REFUNDED')}:{' '}
        {NumberUtils.formatMoney(refundInvoiceOriginalInvoicePayment.amount)}
      </Typography.H3>
      <Typography.Paragraph>
        {t('Invoices:PAID_ON', {
          cardType: refundInvoiceOriginalInvoicePayment.cardType?.name,
          lastFour: refundInvoiceOriginalInvoicePayment.lastFour,
          amount: refundInvoiceOriginalInvoicePayment.amount,
        })}
      </Typography.Paragraph>
    </Grid>
  )
}

export default RefundPenaltyNotesSection
