import React, { useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import deepEqual from 'fast-deep-equal'
import { v4 as uuid } from 'uuid'
import {
  DocumentElementType,
  PermissionArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import Expander from '~/components/common/lists/Expander'
import PanelAccordion from '~/components/common/PanelAccordion'
import { PageBreak } from '~/components/dashboard/admin/catalog/documents/PageBreak'
import { TextEntry } from '~/components/dashboard/admin/catalog/documents/TextEntry'
import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  deleteDocument,
  fetchResolvedDocument,
} from '~/store/actions/documents'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getDocumentsIsDeleting,
  getDocumentsIsReceiving,
  getDocumentsIsSending,
} from '~/store/reducers/documents'
import {
  Document as DocumentType,
  DocumentElementStateItem,
  PageBreakState,
} from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'
import useFieldsChanged from '~/utils/useFieldsChanged'
import { useIsBusinessGroupCheck } from '~/utils/useIsBusinessGroupCheck'

import { AddDocumentElementMenu } from './AddDocumentElementMenu'
import Document, { DocumentHandle } from './Document'
import { DocumentCheckbox } from './DocumentCheckbox'
import { DocumentDateTimePicker } from './DocumentDateTimePicker'
import { DocumentRadioGroup } from './DocumentRadioGroup'
import { MultipleSelect } from './MultipleSelect'
import { RichTextWithPlaceholders } from './RichTextWithPlaceholders'
import { SignatureBox } from './SignatureBox'

const DocumentElementMap = {
  [DocumentElementType.TEXT]: RichTextWithPlaceholders,
  [DocumentElementType.PAGE_BREAK]: PageBreak,
  [DocumentElementType.DATE]: DocumentDateTimePicker,
  [DocumentElementType.DATE_TIME]: DocumentDateTimePicker,
  [DocumentElementType.SINGLE_SELECT]: DocumentRadioGroup,
  [DocumentElementType.MULTI_SELECT]: MultipleSelect,
  [DocumentElementType.SINGLE_CHECKBOX]: DocumentCheckbox,
  [DocumentElementType.SIGNATURE]: SignatureBox,
  [DocumentElementType.TEXT_FIELD]: TextEntry,
}

const StyledExpander = styled(Expander)`
  && {
    max-height: calc(100vh - 140px);
    padding: 0;
  }
`

export interface DocumentDetailsNewProps {
  disableClone?: boolean
  disableDelete?: boolean
  document: DocumentType
  hasUnsavedData?: boolean
  initialDocumentElements?: Record<string, DocumentElementStateItem>
  initialDocumentElementsList?: string[]
  initialPageList?: string[]
  onClose: () => void
  onSave: (document: DocumentType) => void
  saveButtonLabel?: string
}

export const DocumentDetailsNew = ({
  document,
  onClose,
  onSave,
  disableDelete = false,
  disableClone = false,
  initialDocumentElements = {},
  initialDocumentElementsList = [],
  initialPageList = [],
  saveButtonLabel,
  hasUnsavedData = false,
}: DocumentDetailsNewProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const isSending = useSelector(getDocumentsIsSending)
  const isDeleting = useSelector(getDocumentsIsDeleting)
  const isReceiving = useSelector(getDocumentsIsReceiving)

  const permissions = useSelector(getCRUDByArea(PermissionArea.FORM))

  const isGroupDocumentsSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_DOCUMENTS_SHARING),
  )

  const { t } = useTranslation('Common')

  const [openDocumentDialog, closeDocumentDialog] = useDialog(
    DialogNames.DOCUMENT,
  )
  const [openDocumentFormPreviewDialog] = useDialog(
    DialogNames.DOCUMENT_FORM_PREVIEW_DIALOG,
  )
  const [openDeleteDocumentAlert, closeDeleteDocumentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [isDocumentChanged, setIsDocumentChanged] = useState(false)
  const [documentElements, setDocumentElements] = useState<
    Record<string, DocumentElementStateItem>
  >(initialDocumentElements)
  const [documentElementsList, setDocumentElementsList] = useState<string[]>(
    initialDocumentElementsList,
  )

  const [pageList, setPageList] = useState<string[]>(initialPageList)

  const documentRef = useRef<DocumentHandle>(null)

  const disabled = useIsBusinessGroupCheck(
    document?.businessId,
    isGroupDocumentsSharingEnabled,
  )

  const { fields, validate } = useFields([
    {
      name: 'name',
      label: t('Common:NAME'),
      validators: ['required'],
      initialValue: document.name,
    },
  ])

  const { name } = fields

  const hasUnsavedChanges =
    (hasUnsavedData || isDocumentChanged) &&
    Boolean(documentElementsList.length)

  const getDocumentToSave = (
    list: string[],
    elements: Record<string, DocumentElementStateItem>,
  ) => ({
    ...documentRef.current?.get(),
    name: name.value,
    template: {
      extension: 'html',
      documentElements: list.map((id, index) => ({
        ...elements[id],
        id: initialDocumentElements[id] ? id : undefined,
        sequenceNumber: index + 1,
        page: undefined,
        totalPages: undefined,
      })),
    },
  })

  const getUnsavedData = (
    list: string[],
    elements: Record<string, DocumentElementStateItem>,
  ) => {
    if (!document) return false

    const {
      type,
      includeInKiosk,
      required,
      appointmentTypeIds,
      speciesIds,
      genderRestrictions,
      active,
      displayLocationIds,
      subject,
    } = documentRef.current?.get() || {}

    const newDocumentFields = {
      name: name.value,
      type,
      includeInKiosk,
      required,
      appointmentTypeIds,
      speciesIds,
      genderRestrictions,
      active,
      displayLocationIds,
      subject,
    }

    const oldDocumentFields = {
      name: document.name,
      type: document.type,
      includeInKiosk: document.includeInKiosk,
      required: document.required,
      appointmentTypeIds: document.appointmentTypeIds,
      speciesIds: document.speciesIds,
      genderRestrictions: document.genderRestrictions,
      active: document.active,
      displayLocationIds: document.displayLocationIds,
      subject: document.subject,
    }

    return (
      !deepEqual(initialDocumentElements, elements) ||
      !deepEqual(initialDocumentElementsList, list) ||
      !deepEqual(oldDocumentFields, newDocumentFields)
    )
  }

  const onDataChange = (
    list: string[],
    elements: Record<string, DocumentElementStateItem>,
  ) => {
    setIsDocumentChanged(getUnsavedData(list, elements))
  }

  useFieldsChanged(() => {
    onDataChange(documentElementsList, documentElements)
  }, fields)

  const validateForm = () => documentRef.current?.validate() && validate()

  const onSaveRequested = () => {
    if (validateForm()) {
      setIsDocumentChanged(false)
      const newDocument = getDocumentToSave(
        documentElementsList,
        documentElements,
      )
      onSave(newDocument)
    }
  }

  const onDeleteRequested = () => {
    openDeleteDocumentAlert({
      message: getDeleteConfirmMessage(t('Common:DOCUMENT_ONE').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteDocumentAlert(),
      onOk: () => {
        dispatch(deleteDocument(document.id))
        closeDeleteDocumentAlert()
      },
    })
  }

  const onCloneRequested = () => {
    openDocumentDialog({
      PreviewProps: { hideEmail: true, hidePrint: true },
      onDocumentCandidateReady: (newDocument: DocumentType) => {
        navigate(`/admin/catalog/documents/clone${location.search}`, {
          state: {
            cloneData: {
              initialDocumentElements,
              initialDocumentElementsList,
              initialPageList,
              newDocument: { ...newDocument, businessId: undefined },
            },
          },
        })
        closeDocumentDialog()
      },
      document: {
        ...document,
        name: `${document.name}_copy`,
      } as DocumentType,
    })
  }

  const onPreviewRequested = () => {
    const newDocument = getDocumentToSave(
      documentElementsList,
      documentElements,
    )
    dispatch(fetchResolvedDocument({ ...newDocument, id: null }))
    openDocumentFormPreviewDialog({ document: newDocument })
  }

  const handleAddPageBreak = (id: string) => {
    const newPageCount = pageList.length + 1

    setDocumentElements((prevDocumentElements) => {
      const updatedElements = { ...prevDocumentElements }
      // Update all existing page breaks with the new total page count
      pageList.forEach((pageId) => {
        const element = updatedElements[pageId] as PageBreakState
        updatedElements[pageId] = {
          ...element,
          totalPages: newPageCount,
        }
      })
      // Add the new page break element with the current page number and total pages
      updatedElements[id] = {
        id,
        type: DocumentElementType.PAGE_BREAK,
        page: newPageCount,
        totalPages: newPageCount,
      }
      return updatedElements
    })
    setPageList((prevPageList) => [...prevPageList, id])
  }

  const handleAddDocumentElement = ({
    type,
    data = {} as DocumentElementStateItem,
  }: {
    data?: DocumentElementStateItem
    type: DocumentElementType
  }) => {
    const id = uuid()

    if (type === DocumentElementType.PAGE_BREAK) {
      handleAddPageBreak(id)
    } else {
      // @ts-ignores
      setDocumentElements((prevDocumentElements) => ({
        ...prevDocumentElements,
        [id]: { id, type, ...data },
      }))
    }

    setDocumentElementsList((prevList) => {
      const updatedList = [...prevList, id]
      onDataChange(updatedList, documentElements)
      return updatedList
    })
  }

  const handleDeletePageBreak = (id: string) => {
    setDocumentElements((prevDocumentElements) => {
      const updatedElements = { ...prevDocumentElements }

      const updatedPageList = pageList.filter((pageId) => id !== pageId)

      delete updatedElements[id]

      updatedPageList.forEach((pageId: string, index) => {
        updatedElements[pageId] = {
          ...updatedElements[pageId],
          page: index + 1,
          totalPages: updatedPageList.length,
        } as PageBreakState
      })

      setPageList(updatedPageList)

      return updatedElements
    })
  }

  const handleDeleteDocumentElement = (id: string) => {
    const { type } = documentElements[id]

    if (type === DocumentElementType.PAGE_BREAK) {
      handleDeletePageBreak(id)
    } else {
      setDocumentElements((prevDocumentElements) => {
        const updatedDocumentElements = { ...prevDocumentElements }
        delete updatedDocumentElements[id]
        return updatedDocumentElements
      })
    }

    setDocumentElementsList((prevList) => {
      const updatedList = prevList.filter((documentId) => documentId !== id)
      onDataChange(updatedList, documentElements)
      return updatedList
    })
  }

  const handleUpdateDocumentElement = (
    id: string,
    updatedData: Partial<DocumentElementStateItem>,
  ) => {
    setDocumentElements((prevDocumentElements) => {
      const updatedDocumentElements = { ...prevDocumentElements }
      if (updatedDocumentElements[id]) {
        // @ts-ignore
        updatedDocumentElements[id] = {
          ...updatedDocumentElements[id],
          ...updatedData,
        }
      }
      onDataChange(documentElementsList, updatedDocumentElements)
      return updatedDocumentElements
    })
  }

  const updatePageNumbers = (list: string[]) => {
    const pageBreakIds = list.filter(
      (id) => documentElements[id]?.type === DocumentElementType.PAGE_BREAK,
    )

    setDocumentElements((prevDocumentElement) => {
      const updatedElements = { ...prevDocumentElement }

      pageBreakIds.forEach((id, index) => {
        updatedElements[id] = {
          ...updatedElements[id],
          page: index + 1,
        } as PageBreakState
      })
      return updatedElements
    })

    setPageList(pageBreakIds)
  }

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (!destination || source.index === destination.index || disabled) return

    const reorderedList = [...documentElementsList]
    const [movedItem] = reorderedList.splice(source.index, 1)
    reorderedList.splice(destination.index, 0, movedItem)

    setDocumentElementsList(reorderedList)
    updatePageNumbers(reorderedList)
    onDataChange(reorderedList, documentElements)
  }

  return (
    <StyledExpander
      expandedItemClass={t('Common:DOCUMENT_ONE').toLowerCase()}
      hasUnsavedData={hasUnsavedChanges}
      isDeleting={isDeleting}
      isFetching={isReceiving}
      isSaving={isSending}
      saveButtonLabel={saveButtonLabel}
      showButtons={permissions.update}
      onBack={onClose}
      onCloneRequested={disableClone ? undefined : onCloneRequested}
      onDeleteRequested={
        disabled || disableDelete ? undefined : onDeleteRequested
      }
      onSaveRequested={onSaveRequested}
    >
      <Grid>
        <Grid
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: (theme) =>
              theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
            backgroundColor: (theme) => theme.colors.brandPageBg,
            padding: 4,
            paddingTop: 1,
          }}
        >
          {disabled && (
            <AlertBanner
              ContainerProps={{ mt: 1, p: 0.5 }}
              title={
                <Typography.Label color="uiNeedsAttention" sx={{ pr: 0.5 }}>
                  {t('Common:GROUP_LEVEL_FORMS_CANNOT_BE_EDITED')}
                </Typography.Label>
              }
              variant="warning"
            />
          )}
          <Grid container pb={2} wrap="nowrap">
            <PuiTextField
              disabled={disabled}
              field={name}
              label={`${name.label}*`}
            />
            <Grid alignSelf="flex-end" pb={2} px={3}>
              <PreviewButton
                noWrap
                fontSize="1.4rem"
                label={t('Common:PREVIEW_FORM')}
                onClick={onPreviewRequested}
              />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap">
            <PanelAccordion noPadding title={t('Common:FORM_CONFIGURATION')}>
              <Document
                isTypeDisabled
                view
                disabled={disabled}
                document={document}
                nameField={name}
                ref={documentRef}
                onDocumentChange={() =>
                  onDataChange(documentElementsList, documentElements)
                }
              />
            </PanelAccordion>
            <Grid alignSelf="flex-end" px={2} py={0.5}>
              <AddDocumentElementMenu
                addDocumentElement={handleAddDocumentElement}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="document-elements">
            {(provided) => (
              <Grid
                px={4}
                py={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {documentElementsList.map((id: string, index: number) => {
                  const documentElement = documentElements[id]
                  const DocumentElementComponent =
                    DocumentElementMap[
                      documentElement.type as DocumentElementType
                    ]

                  return !disabled ? (
                    <Draggable draggableId={id} index={index} key={id}>
                      {(innerProvided, snapshot) => (
                        <Grid
                          item
                          mb={2}
                          ref={innerProvided.innerRef}
                          xs={12}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                        >
                          <DocumentElementComponent
                            // @ts-ignore
                            documentElement={documentElement}
                            isDragging={snapshot.isDragging}
                            onDelete={() => handleDeleteDocumentElement(id)}
                            onUpdate={(
                              element: Partial<DocumentElementStateItem>,
                            ) => handleUpdateDocumentElement(id, element)}
                          />
                        </Grid>
                      )}
                    </Draggable>
                  ) : (
                    <Grid item key={id} mb={2} xs={12}>
                      <DocumentElementComponent
                        // @ts-ignore
                        documentElement={documentElement}
                      />
                    </Grid>
                  )
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </StyledExpander>
  )
}
