import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { TimelineEntryType } from '~/constants/timelineConstants'
import { updateTimelineFilters } from '~/store/actions/timeline'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTimelineFilters } from '~/store/reducers/timeline'
import { TimelineConfigItem } from '~/types'

import TimelineFilter from './TimelineFilter'
import useTimelineItems from './timelineItems'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    filterRow: {
      width: 'fit-content',
    },
    filterContainer: {
      marginBottom: theme.spacing(2),
      width: 'fit-content',
    },
    toggleButton: {
      padding: '4px 13px',
      textTransform: 'none',
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'TimelineFilters' },
)

export interface TimelineFiltersProps {
  classes?: ClassesType<typeof useStyles>
  collapseConfig?: {
    isExpandedByDefault: boolean
    primaryFilters: TimelineEntryType[]
  }
  filtersSelectedByDefault?: TimelineEntryType[]
  isHorizontalLayout?: boolean
}

const TimelineFilters = ({
  classes: classesProp,
  collapseConfig,
  filtersSelectedByDefault,
  isHorizontalLayout = false,
}: TimelineFiltersProps) => {
  const dispatch = useDispatch()
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const [searchParams, setSearchParams] = useSearchParams()

  const filters = useSelector(getTimelineFilters)
  const isContextBackButtonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CONTEXTUAL_BACK_BUTTON),
  )

  const urlFilters = searchParams.get('timelineFilters')
  const filtersFromStore = filters.join(',')
  const [isExpanded, setIsExpanded] = useState(
    !collapseConfig || collapseConfig.isExpandedByDefault,
  )
  const timelineItems = useTimelineItems()
  const filteredTimelineItems = Object.values(timelineItems).filter(
    (item) => !item.skipInFilters && item.permission,
  )

  const sortedTimelineItems: TimelineConfigItem[] = R.sortBy(
    R.pipe(R.prop('label'), R.toLower),
    filteredTimelineItems,
  )

  const primaryTimelineItems: TimelineConfigItem[] =
    collapseConfig?.primaryFilters
      ? filteredTimelineItems
          .filter(({ type }) => collapseConfig.primaryFilters.includes(type))
          .sort(
            (a, b) =>
              collapseConfig.primaryFilters.indexOf(a.type) -
              collapseConfig.primaryFilters.indexOf(b.type),
          )
      : []

  const isCollapsible =
    collapseConfig &&
    primaryTimelineItems.length > 0 &&
    primaryTimelineItems.length < sortedTimelineItems.length

  const timelineItemsToRender =
    isExpanded || !isCollapsible ? sortedTimelineItems : primaryTimelineItems

  const toggleIsExpanded = () =>
    setIsExpanded((prevIsExpanded) => !prevIsExpanded)

  const onSelect = (type: TimelineEntryType, selected: boolean) => {
    const prevFilters = filters.filter((filter) => filter !== type)
    const newFilters = selected ? [] : [type]
    const allFilters = [...prevFilters, ...newFilters]

    if (isContextBackButtonEnabled) {
      if (allFilters.length > 0) {
        searchParams.set('timelineFilters', allFilters.join(','))
      } else {
        searchParams.delete('timelineFilters')
      }
      setSearchParams(searchParams)
    }

    dispatch(updateTimelineFilters(allFilters))
  }

  const onClear = () => {
    if (isContextBackButtonEnabled) {
      searchParams.delete('timelineFilters')
      setSearchParams(searchParams)
    }

    dispatch(updateTimelineFilters([]))
  }

  useEffect(() => {
    if (filtersSelectedByDefault && filtersSelectedByDefault.length) {
      const prevFilters = filters.filter(
        (filter) => !filtersSelectedByDefault.includes(filter),
      )
      const allFilters = [...prevFilters, ...filtersSelectedByDefault]

      if (isContextBackButtonEnabled) {
        searchParams.set('timelineFilters', allFilters.join(','))
        setSearchParams(searchParams)
      }
      dispatch(updateTimelineFilters(allFilters))
    }
  }, [filtersSelectedByDefault])

  // Sync params from URL with store, as they would be the source of truthy and store needs to be in sync with it
  const storeAndUrlFiltersAreEqual = filtersFromStore === urlFilters
  useEffect(() => {
    if (!isContextBackButtonEnabled) {
      return
    }

    if (!storeAndUrlFiltersAreEqual) {
      dispatch(
        updateTimelineFilters(
          urlFilters ? (urlFilters.split(',') as TimelineEntryType[]) : [],
        ),
      )
    }
  }, [storeAndUrlFiltersAreEqual])

  const itemMarginRight = isHorizontalLayout ? 1 : 0

  return (
    <Grid
      container
      item
      aria-label="timeline-filters"
      className={classes.filterRow}
      justifyContent="center"
      lg={4}
      style={{ flexBasis: isHorizontalLayout ? 1 : undefined }}
    >
      <Grid
        container
        item
        aria-label="timeline-filters"
        className={classes.filterRow}
        direction={isHorizontalLayout ? 'row' : 'column'}
        pl={isHorizontalLayout ? 0 : 1}
      >
        <Grid item className={classes.filterContainer} mr={itemMarginRight}>
          <TimelineFilter
            main
            label={t('Common:DESELECT_ALL')}
            selected={Boolean(filters.length)}
            onSelect={() => onClear()}
          />
        </Grid>
        {timelineItemsToRender.map(({ icon, label, important, type }) => {
          const selected = filters.indexOf(type) > -1

          return (
            <Grid
              item
              className={classes.filterContainer}
              key={label}
              mr={itemMarginRight}
            >
              <TimelineFilter
                icon={icon}
                important={important}
                label={label}
                selected={selected}
                onSelect={() => onSelect(type, selected)}
              />
            </Grid>
          )
        })}
        {isCollapsible && (
          <Grid
            item
            className={classNames(
              classes.filterContainer,
              classes.toggleButton,
            )}
            component={Button}
            endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            mr={itemMarginRight}
            variant="text"
            onClick={toggleIsExpanded}
          >
            {isExpanded ? t('Common:LESS_FILTERS') : t('Common:ALL_FILTERS')}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default TimelineFilters
