import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import InfoList from '~/components/common/InfoList'
import {
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { Variation } from '~/types'

import { getOnHandAmount } from '../../../inventoryUtils'

interface VariationBasicInformationProps {
  defaultValue?: string
  variation: Variation
}

export const VariationBasicInformation = ({
  variation,
  defaultValue = '',
}: VariationBasicInformationProps) => {
  const { t } = useTranslation('Common')
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  const getOnHandAmountLabel = getOnHandAmount(
    InventoryProductSizeUnit,
    PackageType,
  )

  const { name, cumulusId } = variation

  return (
    <InfoList
      items={[
        {
          name: t('Common:VARIATION_NAME'),
          value: name || defaultValue,
        },
        {
          name: t('Common:INTERNAL_PART_NUMBER'),
          value: cumulusId || defaultValue,
        },
        {
          name: t('Common:ON-HAND'),
          value: getOnHandAmountLabel(variation!) || defaultValue,
        },
      ]}
    />
  )
}
