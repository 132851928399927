import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Nil, UnitUtils } from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import InfoList from '~/components/common/InfoList'
import { getUnitsState } from '~/store/duck/settings'
import { Variation } from '~/types'

interface VariationRestrictionProps {
  defaultValue?: string
  variation: Variation
}

export const VariationRestriction = ({
  variation,
  defaultValue,
}: VariationRestrictionProps) => {
  const { t } = useTranslation('Common')
  const unitsState = useSelector(getUnitsState)

  const hasMinWeight = typeof variation.weightMinimum === 'number'
  const hasMaxWeight = typeof variation.weightMaximum === 'number'

  const getWeightWithUnits = (weightValue?: number | Nil) =>
    weightValue
      ? `${UnitUtils.convertUnitsForDisplay(
          UnitTypes.WEIGHT,
          weightValue,
          unitsState,
        )}\u00A0${unitsState.weight}`
      : ''

  const weightRestrictionValue =
    hasMaxWeight && hasMinWeight
      ? t('Admin:CATALOG.INVENTORY_RESTRICTION.WEIGHT_MIN_TO_MAX', {
          minWeight: getWeightWithUnits(variation.weightMinimum),
          maxWeight: getWeightWithUnits(variation.weightMaximum),
        })
      : hasMinWeight
        ? t('Admin:CATALOG.INVENTORY_RESTRICTION.WEIGHT_MIN_AND_UP', {
            minWeight: getWeightWithUnits(variation.weightMinimum),
          })
        : hasMaxWeight
          ? t('Admin:CATALOG.INVENTORY_RESTRICTION.WEIGHT_MAX_OR_LESS', {
              maxWeight: getWeightWithUnits(variation.weightMaximum),
            })
          : defaultValue

  return (
    <InfoList
      items={[
        {
          name: t('Common:INPUTS.WEIGHT_RANGE_INPUT.WEIGHT_RANGE'),
          value: weightRestrictionValue,
        },
      ]}
    />
  )
}
