import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Field, NumberUtils } from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import PuiSwitch from '~/components/common/PuiSwitch'
import { Price } from '~/types'

interface VariationPricingProps {
  prices: Array<{
    fields: {
      discountAllowed: Field
      taxable: Field
    }
    price: Price
  }>
}

export const VariationPrice = ({ prices }: VariationPricingProps) => {
  const { t } = useTranslation('Common')

  return (
    <Grid container spacing={3}>
      {prices.map(
        ({
          price: { id, price, minCharge, dispensingFee },
          fields: { discountAllowed, taxable },
        }) => (
          <Grid container item key={id}>
            <InfoList
              items={[
                {
                  name: t('Common:CLIENT_PRICE'),
                  value: NumberUtils.formatMoney(price ?? 0),
                },
                {
                  name: t('Common:MINIMUM_CHARGE'),
                  value: NumberUtils.formatMoney(minCharge ?? 0),
                },
                {
                  name: t('Common:DISPENSING_FEE'),
                  value: NumberUtils.formatMoney(dispensingFee ?? 0),
                },
              ]}
            />
            <Grid container item flexDirection="column" mt={2}>
              <PuiSwitch
                field={discountAllowed}
                label={discountAllowed.label}
              />
              <PuiSwitch field={taxable} label={taxable.label} />
            </Grid>
          </Grid>
        ),
      )}
    </Grid>
  )
}
